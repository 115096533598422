
import { ValidationObserver } from 'vee-validate';
import { charityCategories } from '@/modules/charityData';

import UPDATE_PARTNERSHIP_MUTATION from '@/graphql/mutations/UpdatePartnership';
import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';
import GET_PARTNERSHIP_QUERY from '@/graphql/queries/GetPartnership';
import GET_COUPONS_BY_PARTNERSHIPS from '@/graphql/queries/GetCouponsForPartnership';
import GET_USERS_QUERY from '@/graphql/queries/GetUsers';

import confirm from '@/mixins/confirm';
import { partnerships } from '@/mixins/apollo';
import { recursiveRemoveKey, formatDate } from '@/utilities';
import { AU_STATES } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateAdminPartnershipsId',
  components: {
    ValidationObserver,
  },
  mixins: [confirm, partnerships],
  layout: 'admin',
  data() {
    return {
      loading: false,
      availableCharityCategories: charityCategories,
      formData: {
        partnership: null,
      },
      emailSearch: '',
      AU_STATES,
    };
  },
  apollo: {
    partnerships: {
      query: GET_PARTNERSHIPS_QUERY,
      fetchPolicy: 'network-only',
      update: (data) => data?.getPartnerships || [],
    },
    partnership: {
      query: GET_PARTNERSHIP_QUERY,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          id: this.partnershipId,
        };
      },
      update: (data) => {
        return data.getPartnership;
      },
      skip() {
        return !this.partnershipId;
      },
      result({ data }) {
        const partnership = data.getPartnership;

        this.formData.partnership = {
          ...partnership,
          affiliateFeatures: partnership.affiliateFeatures || {
            dashboardEnabled: false,
            willEnabled: false,
            powerOfAttorneyEnabled: false,
            endOfLifeEnabled: false,
            giftEnabled: false,
            onboardingNeedsAssessmentEnabled: false,
            willTierTwoEnabled: false,
            affiliateUserIdEnabled: false,
            couponCodeEnabled: false,
          },
          branding: partnership.branding || {},
        };
      },
    },
    coupons: {
      query: GET_COUPONS_BY_PARTNERSHIPS,
      fetchPolicy: 'network-only',
      variables() {
        return {
          partnershipId: this.partnershipId,
        };
      },
      update: (data) => data && data.getCouponsForPartnership,
    },
    users: {
      query: GET_USERS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          searchQuery: this.emailSearch,
          roles: ['PARTNERSHIP'],
          customOrder: { field: 'email', direction: 'ASC' },
        };
      },
      update: (data) =>
        data?.getUsers?.map((user) => ({
          text: user.email,
          value: user.id,
        })),
    },
  },
  computed: {
    countryRules() {
      return this.formData.partnership.active &&
        !this.formData.partnership.affiliateEnabled
        ? 'required'
        : '';
    },
    partnershipId() {
      return this.$route.params.id;
    },
    landingPages: {
      get() {
        return this.formData.partnership.landingPages?.join('\n');
      },
      set(value) {
        this.formData.partnership.landingPages = value
          .split('\n')
          .filter((item) => item);
      },
    },
    altNames: {
      get() {
        return this.formData.partnership.altNames?.join('\n');
      },
      set(value) {
        this.formData.partnership.altNames = value
          .split('\n')
          .filter((item) => item);
      },
    },
    utmPrefixes: {
      get() {
        return this.formData.partnership.utmPrefixes?.join('\n');
      },
      set(value) {
        this.formData.partnership.utmPrefixes = value
          .split('\n')
          .filter((item) => item);
      },
    },
    partnershipsSelectList() {
      return (this.partnerships || []).reduce(
        (partnershipsList, partnership) => {
          if (partnership.id !== this.partnership.id) {
            partnershipsList.push({
              text: partnership.name,
              value: partnership.id,
            });
          }
          return partnershipsList;
        },
        []
      );
    },
  },
  methods: {
    formatDate,
    setPartnershipActive(partnership) {
      const missingFields = this.getPartnershipMissingFields(partnership);

      if (missingFields.length) {
        partnership.active = !partnership.active;
        return this.$nuxt.$emit('snackbar', {
          placement: 'top-right',
          type: 'error',
          text:
            'The following field(s) are missing: ' + missingFields.join(', '),
        });
      }

      const title = `${partnership.active ? 'Activate' : 'Deactivate'} ${
        partnership.name
      }?`;
      const activationText = `Are you sure you want to activate ${partnership.name}?
         Activating this partnership will display the partnership on the platform and activate their dashboard.`;

      const deactivationText = `Are you sure you want to deactivate ${partnership.name}?
         Deactivating this partnership will remove the partnership from the platform and deactivate their dashboard.`;

      this.$confirm(
        {
          title,
          message: partnership.active ? activationText : deactivationText,
        },
        (action) => {
          if (action) {
            this.updatePartnership(partnership);
          } else {
            partnership.active = !partnership.active;
          }
        }
      );
    },
    showPartnershipArchivedModal() {
      this.$confirm(
        {
          title: `Archive ${this.formData.partnership.name}?`,
          message: 'Are you sure you want to archive this Partnership?',
        },
        (action) => {
          if (action) {
            this.updatePartnership({
              ...this.formData.partnership,
              archived: !this.formData.partnership.archived,
            });
          }
        }
      );
    },
    async updatePartnership(props = {}) {
      const isValid =
        this.$refs.observer && (await this.$refs.observer.validate());

      if (!isValid) {
        return;
      }

      const partnership = {
        ...this.formData.partnership,
        ...props,
      };

      if (partnership.affiliateEnabled && partnership.bequestEnabled) {
        this.$nuxt.$emit('snackbar', {
          placement: 'top-right',
          type: 'error',
          text: 'Bequest and Affiliate cannot be enabled at the same time',
        });
        return;
      }

      partnership.relatedPartnershipIds = partnership.relatedPartnerships
        .filter((partnership) => partnership.id)
        .map((partnership) => partnership.id);

      if (!partnership.affiliateEnabled) {
        partnership.affiliateFeatures = {
          willEnabled: false,
          powerOfAttorneyEnabled: false,
          endOfLifeEnabled: false,
          affiliateUserIdEnabled: false,
          couponCodeEnabled: false,
        };
      }

      if (!partnership.affiliateFeatures.affiliateUserIdEnabled) {
        delete partnership.affiliateFeatures.affiliateUserIdFieldName;
      }

      if (!partnership.affiliateFeatures.couponCodeEnabled) {
        delete partnership.affiliateFeatures.couponCodeFieldName;
      }

      if (partnership.getStartedTitleOne?.length === 0) {
        partnership.getStartedTitleOne = null;
      }
      if (partnership.getStartedDescriptionOne?.length === 0) {
        partnership.getStartedDescriptionOne = null;
      }
      if (partnership.getStartedTitleTwo?.length === 0) {
        partnership.getStartedTitleTwo = null;
      }
      if (partnership.getStartedDescriptionTwo?.length === 0) {
        partnership.getStartedDescriptionTwo = null;
      }
      if (partnership.getStartedTitleThree?.length === 0) {
        partnership.getStartedTitleThree = null;
      }
      if (partnership.getStartedDescriptionThree?.length === 0) {
        partnership.getStartedDescriptionThree = null;
      }

      delete partnership.region;
      delete partnership.parentPartnershipId;
      delete partnership.relatedPartnerships;

      partnership.contacts = partnership.contacts.map((contact) => {
        delete contact.email;
        return contact;
      });

      recursiveRemoveKey(partnership, '__typename');
      recursiveRemoveKey(partnership, 'createdAt');
      recursiveRemoveKey(partnership, 'updatedAt');

      try {
        this.loading = true;
        await this.$apollo.mutate({
          mutation: UPDATE_PARTNERSHIP_MUTATION,
          variables: {
            partnership,
          },
        });

        this.formData.partnership.contacts = partnership.contacts.map(
          (contact) => {
            if (!contact.email) {
              return {
                ...contact,
                email: this.users.find((user) => user.value === contact.userId)
                  .text,
              };
            }
            return contact;
          }
        );
      } catch (error) {
        this.$nuxt.$emit('snackbar', {
          placement: 'top-right',
          type: 'error',
          text: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    addContact() {
      this.formData.partnership.contacts.push({
        partnershipId: this.partnershipId,
        hasDashboardAccess: true,
        isSubscribedToEmails: true,
        email: null,
        userId: null,
      });
    },
    async removeContact(index) {
      const backupContacts = this.formData.partnership.contacts;
      this.formData.partnership.contacts = [];
      backupContacts.splice(index, 1);
      await this.$nextTick();
      this.formData.partnership.contacts = backupContacts;
    },
    addRelatedPartnership() {
      this.formData.partnership.relatedPartnerships.push({
        id: null,
      });
    },
    removeRelatedPartnership(relatedPartnership) {
      this.formData.partnership.relatedPartnerships =
        this.formData.partnership.relatedPartnerships.filter(
          (partnership) => partnership.id !== relatedPartnership.id
        );
    },
    addRegionalPartnership() {
      this.formData.partnership.regionalPartnerships.push({
        id: null,
      });
    },
    removeRegionalPartnership(regionalPartnership) {
      this.formData.partnership.regionalPartnerships =
        this.formData.partnership.regionalPartnerships.filter(
          (partnership) => partnership.id !== regionalPartnership.id
        );
    },
  },
};
