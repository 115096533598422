
import { mapGetters, mapActions } from 'vuex';
import {
  PLAN_FLAG_STATUS,
  PLAN_FLAG_ACTION,
  PLAN_TYPE,
} from '@/utilities/constants';

export default {
  name: 'AppWillFlagAlert',
  props: {
    module: {
      type: String,
      default: null,
    },
    plan: {
      type: String,
      default: PLAN_TYPE.WILL,
      validator: (value) => Object.values(PLAN_TYPE).includes(value),
    },
    backToModuleEnabled: {
      type: Boolean,
      default: false,
    },
    activeFlagsOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visibleFlags: [],
      ignoreFlagModalVisibleWithFlagId: null,
      hiddenFlags: [],
      isInitialVisibleFlagSet: false,
      isInitialExpanded: false,
      addedToCart: false,
      upgradeModalOpeningWithFlagId: null,
      accessLegalAdviceCallOpeningWithFlagId: null,
    };
  },
  computed: {
    ...mapGetters('will-tiers', [
      'isWillTierTwoUpgradeAvailable',
      'hasPurchasedWillTierTwo',
    ]),
    ...mapGetters(['willId']),
    ...mapGetters('flag', ['planFlags']),
    ...mapGetters('modules', ['modules']),
    ...mapGetters('user/cart', ['isInCart']),
    ...mapGetters('directory-person', ['userIsAustralian']),
    planId() {
      return this.plan === PLAN_TYPE.WILL ? this.willId : null;
    },
    filteredFlags() {
      if (this.planFlags && !this.isInitialVisibleFlagSet) {
        this.setInitialVisibleFlag();
      }

      let planFlags = this.planFlags
        .filter((planFlag) => planFlag.planId === this.planId)
        .filter((planFlag) => !this.hiddenFlags.includes(planFlag.id));

      if (this.module) {
        planFlags = planFlags.filter(
          (planFlag) => planFlag.planSegment.module === this.module
        );
      }

      if (planFlags.length && !this.isInitialExpanded) {
        this.setInitialExpanded(planFlags);
      }

      return planFlags.map((planFlag) => {
        const isResolved =
          planFlag?.status?.toUpperCase() === PLAN_FLAG_STATUS.RESOLVED;
        const isIgnored =
          planFlag?.status?.toUpperCase() === PLAN_FLAG_STATUS.IGNORED;
        const isActioned =
          planFlag?.status?.toUpperCase() === PLAN_FLAG_STATUS.ACTIONED;
        const isActionAddWillTierTwoToCart =
          planFlag.flag?.additionalActions?.some(
            (action) =>
              action.action === 'ADD_TO_CART' &&
              action.value === 'WILL_TIER_TWO' &&
              this.userIsAustralian
          );
        const isOpenUpgradeModalAvailable =
          isActionAddWillTierTwoToCart &&
          (this.isWillTierTwoUpgradeAvailable ||
            this.isInCart('WILL_TIER_TWO'));
        const isAccessLACallAvailable =
          isActionAddWillTierTwoToCart && this.hasPurchasedWillTierTwo;
        return {
          ...planFlag,
          isResolved,
          isIgnored,
          isActioned,
          isOpenUpgradeModalAvailable,
          isAccessLACallAvailable,
        };
      });
    },
  },
  methods: {
    ...mapActions('will-tiers', ['addWillTierTwoToCart']),
    ...mapActions('user/cart', ['addToCart']),
    ...mapActions('flag', ['actionPlanFlag', 'getPlanFlags']),
    setInitialVisibleFlag() {
      if (this.activeFlagsOnly) {
        this.hiddenFlags = this.planFlags
          .filter(
            (flag) => flag.status?.toUpperCase() !== PLAN_FLAG_STATUS.ACTIVE
          )
          .map((flag) => flag.id);
      }
      this.isInitialVisibleFlagSet = true;
    },
    setInitialExpanded(filteredFlags) {
      const firstActiveFlag = filteredFlags.find(
        (flag) => flag.status?.toUpperCase() === PLAN_FLAG_STATUS.ACTIVE
      );
      this.visibleFlags = firstActiveFlag ? [firstActiveFlag.id] : [];
      this.isInitialExpanded = true;
    },
    toggleBodyVisibility(id) {
      this.visibleFlags = this.visibleFlags.includes(id)
        ? this.visibleFlags.filter((flag) => flag !== id)
        : [...this.visibleFlags, id];
    },
    async resolveFlag(id) {
      await this.actionPlanFlag({ id, action: PLAN_FLAG_ACTION.RESOLVE });
      this.toggleBodyVisibility(id);
    },
    async ignoreFlag(id) {
      await this.actionPlanFlag({ id, action: PLAN_FLAG_ACTION.IGNORE });
      this.closeIgnoreFlagModal();
      this.toggleBodyVisibility(id);
    },
    openIgnoreFlagModal(id) {
      this.ignoreFlagModalVisibleWithFlagId = id;
    },
    closeIgnoreFlagModal() {
      this.ignoreFlagModalVisibleWithFlagId = null;
    },
    getModuleByFlagKey(flagKey) {
      return this.modules.find((module) => module.flagKey === flagKey);
    },
    isBackToModuleAvailable(planFlag) {
      const module = this.getModuleByFlagKey(planFlag.planSegment?.module);
      return this.backToModuleEnabled && module;
    },
    modulePath(planFlag) {
      const module = this.getModuleByFlagKey(planFlag.planSegment?.module);
      return module ? module.path : null;
    },
    getFlagById(id) {
      return this.planFlags.find((flag) => flag.id === id);
    },
    async actionFlag() {
      this.loading = true;
      await this.actionPlanFlag({
        id: this.upgradeModalOpeningWithFlagId,
        action: PLAN_FLAG_ACTION.ADD_TO_CART,
      });
      await this.getPlanFlags({ planId: this.planId });
      this.loading = false;
    },
  },
};
