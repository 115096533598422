import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0d9d02b1"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppDecoratedText: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/DecoratedText.vue').default,AppTooltip: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Tooltip.vue').default,RuHeadline: require('/home/runner/work/ruckus/ruckus/packages/ruach/atoms/Headline/index.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppFixedFooter: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/snowflakes/FixedFooter.vue').default,AppModuleCard: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/organisms/ModuleCard.vue').default})
