
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'throttle-debounce';

import GET_PARTNERSHIPS_QUERY from '@/graphql/queries/GetPartnerships';
import ADD_PARTNERSHIP_MUTATION from '@/graphql/mutations/AddPartnership';

import confirm from '@/mixins/confirm';
import { partnerships } from '@/mixins/apollo';
import { filterNullish, saveBlobToFile, formatDate } from '@/utilities';

export default {
  name: 'PagesAffiliateAdminPartnershipsIndex',
  mixins: [confirm, partnerships],
  layout: 'admin',
  middleware: ['adminAuthenticated'],
  apollo: {
    partnerships: {
      query: GET_PARTNERSHIPS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          ...(this.partnershipsSearchQuery
            ? { searchQuery: this.partnershipsSearchQuery }
            : {}),
          filters: filterNullish(this.partnershipsFilters),
          limit: this.partnershipsPageSize,
          offset: this.partnershipsPageIndex * this.partnershipsPageSize,
          customOrder: this.partnershipsSortOrder,
        };
      },
      update: (data) => data?.getPartnerships,
    },
  },
  data() {
    return {
      ADD_PARTNERSHIP_MUTATION,
      isNewPartnershipModalVisible: false,
      columns: [
        'Name',
        '# Active Coupons',
        'Created At',
        'Updated At',
        'Active',
      ],
      statusFilters: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Active',
          value: true,
        },
        {
          label: 'Inactive',
          value: false,
        },
      ],
      searchInput: null,
      formData: {
        name: null,
        slug: null,
      },
      partnershipTypeOptions: [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'Affiliate',
          value: 'affiliate',
        },
        {
          text: 'Charity / Not For Profit',
          value: 'charity',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('admin/partnerships', [
      'partnershipsPageIndex',
      'partnershipsPageSize',
      'partnershipsSearchQuery',
      'partnershipsFilters',
      'partnershipsSort',
      'partnershipsSortOrder',
      'partnershipsSortableColumns',
    ]),
    partnershipType: {
      get() {
        if (this.partnershipsFilters.affiliateEnabled) {
          return 'affiliate';
        } else if (this.partnershipsFilters.bequestEnabled) {
          return 'charity';
        }
        return null;
      },
      set(value) {
        this.updateFilters({
          affiliateEnabled: value === 'affiliate' ? true : null,
          bequestEnabled: value === 'charity' ? true : null,
        });
      },
    },
    addPartnershipFormData() {
      return {
        partnership: {
          ...this.formData,
          abn: '',
          address: '',
          logo: '',
          categories: [],
          landingPages: [],
          altNames: [],
          utmPrefixes: [],
          featuredFive: false,
          dashboardEnabled: false,
          bequestEnabled: false,
          affiliateEnabled: false,
          active: false,
          archived: false,
          region: null,
          relatedPartnershipIds: [],
          contacts: [],
          appHeaderAffiliateLogoShown: false,
          getStartedAffiliateLogoShown: false,
        },
      };
    },
  },
  mounted() {
    this.searchInput = this.partnershipsSearchQuery;
  },
  methods: {
    ...mapActions('admin/partnerships', [
      'nextPartnershipsPage',
      'prevPartnershipsPage',
      'setPartnershipsFilters',
      'setPartnershipsSearchQuery',
      'setPartnershipsSort',
    ]),
    formatDate,
    setNewPartnershipModalVisibility(value) {
      this.isNewPartnershipModalVisible = value;
    },
    async addPartnership(_store, res) {
      const createdPartnership = res.data.createPartnership;
      await this.$router.push(
        this.localePath(`/admin/partnerships/${createdPartnership.id}`)
      );
    },
    downloadCsv() {
      try {
        const fields = [
          'Name',
          'Categories',
          'Active',
          'Active Coupons',
          'Country',
          'State',
          'Featured Five',
        ];
        const blob = new Blob(
          [
            `${fields.join(',')}\n`,
            ...this.partnerships.map((partnership) => {
              let csvString = '';
              csvString += `"${partnership.name}",`;
              csvString += `"${partnership.categories.join('\n')}",`;
              csvString += `"${partnership.active}",`;
              csvString += `"${partnership.activeCouponCodes.join('\n')}",`;
              csvString += `"${partnership.country || ''}",`;
              csvString += `"${partnership.region || ''}",`;
              csvString += `"${partnership.featuredFive}"`;
              csvString += '\n';
              return csvString;
            }),
          ],
          {
            type: 'text/csv;charset=utf-8',
          }
        );
        saveBlobToFile(blob, `partnerships-list-${Date.now()}.csv`);
      } catch (error) {
        console.error('Error downloading CSV', error);
        window.$nuxt.$emit('snackbar', {
          placement: 'top-right',
          type: 'error',
          text: 'Failed to download partnerships list',
        });
      }
    },
    reloadPartnerships() {
      this.$apollo.queries.partnerships.refetch();
    },
    setPartnershipStatus(partnership) {
      const missingFields = this.getPartnershipMissingFields(partnership);
      if (missingFields.length) {
        return this.$nuxt.$emit('snackbar', {
          placement: 'top-right',
          type: 'error',
          text:
            'The following field(s) are missing: ' + missingFields.join(', '),
        });
      }
      const title = `${!partnership.active ? 'Activate' : 'Deactivate'} ${
        partnership.name
      }?`;
      const activationText = `Are you sure you want to activate ${partnership.name}?
       Activating this partnership will display the partnership on the platform and activate their dashboard.`;
      const deactivationText = `Are you sure you want to deactivate ${partnership.name}?
       Deactivating this partnership will remove the partnership from the platform and deactivate their dashboard.`;
      this.$confirm(
        {
          title,
          message: !partnership.active ? activationText : deactivationText,
        },
        async (action) => {
          if (action) {
            await this.updatePartnershipPartialData(partnership.id, {
              active: !partnership.active,
            });
          }
        }
      );
    },
    search: debounce(300, function (event) {
      this.setPartnershipsSearchQuery(event.target.value.trim());
    }),
    updateFilters(value) {
      this.setPartnershipsFilters({
        ...this.partnershipsFilters,
        ...value,
      });
    },
    toggleFeaturedFiveFilter() {
      this.updateFilters({
        featuredFive: this.partnershipsFilters.featuredFive ? null : true,
      });
    },
  },
};
