import { render, staticRenderFns } from "./SubscriptionOptOutModal.vue?vue&type=template&id=97754ef8"
import script from "./SubscriptionOptOutModal.vue?vue&type=script&lang=js"
export * from "./SubscriptionOptOutModal.vue?vue&type=script&lang=js"
import style0 from "./SubscriptionOptOutModal.vue?vue&type=style&index=0&id=97754ef8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WpButton: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/wrappers/Button.vue').default,AppButtons: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/atoms/Buttons.vue').default,AppModal: require('/home/runner/work/ruckus/ruckus/apps/app.safewill.com/components/molecules/Modal.vue').default})
