
import { DISCOUNT_OPTIONS } from '@/utilities/constants';

export default {
  name: 'OrganismsAdminCouponProductValue',
  props: {
    id: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'PERCENTAGE',
    },
    factor: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    discountOptions() {
      return DISCOUNT_OPTIONS.map((option) => ({
        value: option,
        text: this.$t(`commons.discountType.${option}`),
      }));
    },
    enabledValue: {
      get() {
        return this.enabled;
      },
      set(value) {
        this.$emit('update:type', 'PERCENTAGE');
        this.$emit('update:factor', 0);
        this.$emit('update:enabled', value);
      },
    },
    typeValue: {
      get() {
        return this.type;
      },
      set(value) {
        this.$emit('update:type', value);
      },
    },
    factorValue: {
      get() {
        return this.factor;
      },
      set(value) {
        this.$emit('update:factor', value);
      },
    },
  },
};
