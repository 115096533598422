
import { mapActions, mapGetters } from 'vuex';
import { formatPrice, formatDate } from '@/utilities';
import { subscription, user } from '@/mixins/apollo';

export default {
  name: 'AppAccountSubscriptionStatusCard',
  mixins: [subscription, user],
  computed: {
    ...mapGetters('application/prices', ['subscriptionRenewalPrice']),
    price() {
      return this.formatPrice(this.subscriptionRenewalPrice);
    },
    isCancelled() {
      return !this.subscription.autoRenew;
    },
    renewalText() {
      return this.subscription.autoRenew
        ? this.$t('components.subscriptionStatusCard.renewText.isAutoRenew', {
            price: this.price,
            renewalDate: this.subscriptionRenewalDate,
          })
        : this.$t(
            'components.subscriptionStatusCard.renewText.isNotAutoRenew',
            {
              price: this.price,
            }
          );
    },
    subscriptionRenewalDate() {
      const date = new Date(Number(this.subscription.expiresAt));
      date.setDate(date.getDate() + 1);
      return formatDate(date, 'dS mmmm yyyy');
    },
    statusText() {
      return this.hasValidSubscription
        ? this.$t(
            'components.subscriptionStatusCard.statusText.hasValidSubscription',
            {
              date: this.subscriptionRenewalDate,
            }
          )
        : this.$t(
            'components.subscriptionStatusCard.statusText.hasExpiredSubscription'
          );
    },
  },
  methods: {
    ...mapActions('user/cart', ['addToCart']),
    ...mapActions('subscription', ['setAutoRenew']),
    formatPrice,
    updateCardDetails() {
      this.$router.push({ path: this.localePath(`/checkout/update-card`) });
    },
    async unlockAccount() {
      await this.addToCart({ codes: [this.requiredUnlockProduct] });
      await this.$router.push({ path: this.localePath(`/checkout/cart`) });
    },
    enableAutoRenew() {
      if (this.hasValidSubscription && this.hasValidPaymentMethod) {
        this.setAutoRenew(true);
      } else {
        this.updateCardDetails();
      }
    },
    disableAutoRenew() {
      this.setAutoRenew(false);
    },
  },
};
