
import { formatDate } from '@/utilities';

export default {
  name: 'AppOrdersDetails',
  props: {
    orders: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  methods: {
    centsToDollars(cents) {
      return cents / 100;
    },
    buildItemsRows(item, index) {
      const rows = [];

      rows.push([`${this.$t('components.ordersDetails.item')} ${index + 1}`]);
      rows.push([this.$t('components.ordersDetails.product'), item.product]);
      rows.push([
        this.$t('components.ordersDetails.currency'),
        item.currency.toUpperCase(),
      ]);
      rows.push([
        this.$t('components.ordersDetails.value'),
        '$' + this.centsToDollars(item.valueInCents),
      ]);
      rows.push([
        this.$t('components.ordersDetails.createdAt'),
        formatDate(item.createdAt),
      ]);
      rows.push([
        this.$t('components.ordersDetails.cancelled'),
        item.cancelled,
      ]);
      return rows;
    },
    buildOrderRows(order) {
      return [
        [this.$t('components.ordersDetails.id'), order.id],
        [
          this.$t('components.ordersDetails.currency'),
          order.currency.toUpperCase(),
        ],
        [
          this.$t('components.ordersDetails.value'),
          '$' + this.centsToDollars(order.valueInCents),
        ],
        [
          this.$t('components.ordersDetails.taxValue'),
          '$' + this.centsToDollars(order.taxValue),
        ],
        [
          this.$t('components.ordersDetails.createdAt'),
          formatDate(order.createdAt),
        ],
        [
          this.$t('components.ordersDetails.paymentMethod'),
          order.paymentMethod,
        ],
        [
          this.$t('components.ordersDetails.externalPaymentId'),
          order.externalPaymentId,
        ],
        [
          this.$t('components.ordersDetails.discountCode'),
          order.couponCode || order.inviteCode,
          order.couponCode ? `/sw/admin/coupons/${order.discountId}` : '',
        ],
      ];
    },
  },
};
