
import { mapActions } from 'vuex';

export default {
  name: 'AppCheckoutSubscriptionOptOutModal',
  props: {},
  emits: ['close', 'continuePayment', 'skipPayment'],
  methods: {
    ...mapActions('user/cart', ['setSubscriptionAutoRenewal']),
    closeModal() {
      this.$emit('close');
    },
    skipCardCheckout() {
      this.closeModal();
      this.$emit('skipPayment');
    },
    cardCheckout() {
      this.setSubscriptionAutoRenewal(true);
      this.closeModal();
      this.$emit('continuePayment');
    },
  },
};
