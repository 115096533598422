
import { RELATIONSHIP_TYPE } from '@/utilities/constants';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    valueLocked: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    hidePartner: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mobileRelationshipOptions() {
      return this.relationshipOptions.map((relationshipOption) => ({
        ...relationshipOption,
        text: relationshipOption.disabled
          ? `🔒 ${relationshipOption.text}`
          : relationshipOption.text,
      }));
    },
    relationshipOptions() {
      const relationships = Object.keys(RELATIONSHIP_TYPE)
        .filter((key) => key !== RELATIONSHIP_TYPE.UNKNOWN)
        .map((key) => {
          const disabled =
            (this.valueLocked && this.value !== RELATIONSHIP_TYPE[key]) ||
            (this.hidePartner &&
              RELATIONSHIP_TYPE[key] === RELATIONSHIP_TYPE.PARTNER);
          return {
            text: `${disabled ? '🔒 ' : ''}${this.$t(`commons.relationships.${key}`)}`,
            value: RELATIONSHIP_TYPE[key],
            disabled,
          };
        });
      return relationships;
    },
    modelValue: {
      get() {
        return this.value === RELATIONSHIP_TYPE.UNKNOWN ? '' : this.value;
      },
      set(value) {
        this.update(value);
      },
    },
  },
  methods: {
    update(value) {
      this.$emit('input', value);
    },
  },
};
