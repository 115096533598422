
import { mapActions, mapGetters } from 'vuex';
import GET_INSTITUTION_BY_ID_QUERY from '@/graphql/queries/GetInstitutionById';
import ARCHIVE_INSTITUTION_MUTATION from '@/graphql/mutations/ArchiveInstitution';
import UPDATE_INSTITUTION_MUTATION from '@/graphql/mutations/UpdateInstitution';

import confirm from '@/mixins/confirm';
import { recursiveRemoveKey, formatDate } from '@/utilities';

export default {
  name: 'PagesAffiliateAdminInstitutionsId',
  mixins: [confirm],
  apollo: {
    institution: {
      query: GET_INSTITUTION_BY_ID_QUERY,
      fetchPolicy: 'network-only',
      skip() {
        return !this.id;
      },
      variables() {
        return {
          id: this.id,
        };
      },
      update: (data) => data.getInstitutionById,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('admin/institutions', ['institutionsTypes']),
    id() {
      return this.$route.params.id;
    },
    typeIsOther() {
      return this.institution.type === 'Other';
    },
    type() {
      return this.typeIsOther
        ? this.institution.typeOther
        : this.institution.type;
    },
  },
  mounted() {
    this.getInstitutionsTypes();
  },
  methods: {
    ...mapActions('admin/institutions', ['getInstitutionsTypes']),
    formatDate,
    showArchiveInstitutionModal() {
      this.$confirm(
        {
          title: `Archive ${this.institution.name}?`,
          message: 'Are you sure you want to archive this institution?',
        },
        async (action) => {
          if (action) {
            await this.archiveInstitution();
            this.$router.push(this.localePath('/admin/institutions'));
          }
        }
      );
    },
    async updateInstitutionData() {
      this.loading = true;
      const newInstitution = { ...this.institution, type: this.type };
      recursiveRemoveKey(newInstitution, '__typename');
      recursiveRemoveKey(newInstitution, 'createdAt');
      recursiveRemoveKey(newInstitution, 'updatedAt');

      await this.updateInstitution(newInstitution);

      this.loading = false;
    },
    async updateInstitution(variables) {
      await this.$apollo.mutate({
        mutation: UPDATE_INSTITUTION_MUTATION,
        variables,
      });
      await this.getInstitutionsTypes();
      await this.$apollo.queries.institution.refetch();
    },
    async archiveInstitution() {
      await this.$apollo.mutate({
        mutation: ARCHIVE_INSTITUTION_MUTATION,
        variables: {
          id: this.id,
        },
      });
    },
  },
};
