
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters, mapActions } from 'vuex';

import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import DOWNLOAD_WILL_WORD_DOC_QUERY from '@/graphql/queries/DownloadWillWordDoc';
import UPDATE_WILL_STATUS_MUTATION from '@/graphql/mutations/UpdateWillStatus';
import VALIDATE_WILL_QUERY from '@/graphql/queries/ValidateWill';
import GENERATE_WILL_MUTATION from '@/graphql/mutations/GenerateWill';
import GET_INVITES from '@/graphql/queries/GetInvites';
import GET_WILL_BY_EMAIL from '@/graphql/queries/GetWillByEmail';
import GET_PARTNERSHIP from '@/graphql/queries/GetPartnership';
import GET_ORDERS from '@/graphql/queries/GetOrders';
import GET_USER_QUERY from '@/graphql/queries/GetUser';
import VERSION_WILL from '@/graphql/mutations/VersionWill';
import {
  recursiveRemoveKey,
  metaArrayToObject,
  yesIfTruthy,
  formatAUD,
  formatError,
  isAdminOrHigher,
  dashIfFalsy,
  convertIsoDateToLegacyDate,
  formatDate,
} from '@/utilities';
import { separateContactsByRole } from '@/utilities/directory-person';
import {
  assets,
  beneficiaries,
  charities,
  executors,
  gifts,
  guardians,
  liabilities,
  will,
} from '@/mixins/apollo';
import files from '@/mixins/files';
import confirm from '@/mixins/confirm';

export default {
  name: 'PagesAffiliateAdminSubmissionsId',
  mixins: [
    assets,
    beneficiaries,
    charities,
    executors,
    files,
    gifts,
    guardians,
    liabilities,

    will,
    confirm,
  ],
  layout: 'admin',
  apollo: {
    user: {
      query: GET_USER_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.will?.userId,
        };
      },
      update: (data) => data.getUser,
      skip() {
        return !this.will?.userId;
      },
    },
    orders: {
      query: GET_ORDERS,
      variables() {
        return {
          userId: this.will?.userId,
        };
      },
      fetchPolicy: 'no-cache',
      update: (data) => data && data.getOrders,
      skip() {
        return !this.will?.userId;
      },
    },
    getWillByEmail: {
      query: GET_WILL_BY_EMAIL,
      variables() {
        return {
          email: this.will?.email,
        };
      },
      skip() {
        return !this.will?.email;
      },
    },
    doc: {
      query: DOWNLOAD_WILL_WORD_DOC_QUERY,
      update: (data) =>
        data.downloadWillWordDoc && data.downloadWillWordDoc.signedUrl,
      variables() {
        return {
          id: this.willId,
        };
      },
      skip() {
        return !this.will?.wordFileId;
      },
    },
    invites: {
      query: GET_INVITES,
      variables() {
        return {
          userId: this.will?.userId,
        };
      },
      skip() {
        return !this.will?.userId;
      },
      update: (data) => data.getInvites,
    },
    transferAffiliate: {
      query: GET_PARTNERSHIP,
      fetchPolicy: 'network-only',
      skip() {
        return !this.willHasLoaded || !this.will?.transferAffiliateId;
      },
      variables() {
        return {
          id: this.will?.transferAffiliateId,
        };
      },
      update: (data) => data.getPartnership || null,
    },
    userAffiliate: {
      query: GET_PARTNERSHIP,
      fetchPolicy: 'network-only',
      skip() {
        return !this.user?.affiliateId;
      },
      variables() {
        return {
          id: this.user?.affiliateId,
        };
      },
      update: (data) => data.getPartnership || null,
    },
  },
  data() {
    const unknownProxy = new Proxy(
      { toString: () => '???' },
      {
        get: (t) => t,
      }
    );
    return {
      DOWNLOAD_WILL_WORD_DOC_QUERY,
      approvingWill: false,
      validatingWill: false,
      versioningWill: false,
      generatingWill: false,
      flaggingWill: false,
      doc: null,
      will: {
        userId: null,
        hashId: null,
        id: null,
        archived: null,
        status: null,
        transferAffiliateId: null,
        transferredAt: null,
        updatedAt: null,
        createdAt: null,
        willFileId: null,
        wordFileId: null,
        email: null,
        isPrimaryEstateSplitEvenly: null,
      },
      willMeta: null,
      showPartnerModal: false,
      showTransferWillModal: false,
      showVersionWillModal: false,
      partnerCode: '',
      approveWillIsDisabled: true,
      downloadPdfIsDisabled: true,
      downloadDocIsDisabled: true,
      flagsAreDisabled: true,
      transferWillIsDisabled: true,
      flagWillIsDisabled: true,
      versionWillIsDisabled: true,
      submissionUserDetails: unknownProxy,
      partnerPerson: undefined,
      childrenPersons: [],
      user: undefined,
      contacts: undefined,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('flag', ['flags', 'planFlags']),
    ...mapGetters('directory-person', ['userDetails']),
    ...mapGetters(['token', 'role']),
    ...mapGetters('modules', ['modules']),
    ...mapGetters('admin/submissions', ['submissionsWillIds', 'submissions']),
    ...mapGetters('tool', ['willHelpAssessmentTool']),
    ...mapGetters('user/pets', ['pets']),
    executorOptionText() {
      if (!this.willMeta.executors_option) {
        return '-';
      }

      switch (this.willMeta.executors_option) {
        case 'professional':
        case 'friendsFamily':
        case 'friendsFamilyAndProfessional':
          return this.willMeta.executors_option;
        default:
          return this.$t(
            `products.executors.${this.willMeta.executors_option}`
          );
      }
    },
    reviewableWillModules() {
      return this.modules.filter((module) => module.key !== 'review');
    },
    willHasLoaded() {
      return !!this.will?.hashId && this.contacts;
    },
    userId() {
      return this.will?.userId;
    },
    willId() {
      return this.$route.params.id;
    },
    hasFlags() {
      return this.planFlags.some((planFlag) => planFlag.status === 'ACTIVE');
    },
    pdfFilename() {
      return `safewill-${this.will.hashId}_${this.submissionUserDetails.lastName},${this.submissionUserDetails.firstName}`.toLowerCase();
    },
    paidInvites() {
      return this.invites?.filter((item) => item.type === 'PAID') ?? [];
    },
    latestWillData() {
      const latestWill = this.getWillByEmail?.will;

      if (latestWill?.id === this.willId) {
        return null;
      }

      return latestWill;
    },
    userIsNewZealandUser() {
      return this.submissionUserDetails?.residentialAddress.country === 'NZ';
    },
    getDuplicateData() {
      let data = null;

      if (this.partnerPerson) {
        // create parter name
        // add all data
        const partnerPersonToUser = () => {
          const { firstName, lastName, dateOfBirth, defaultEmailAddress } =
            this.partnerPerson;
          const newUser = {
            firstName,
            lastName,
            dateOfBirth,
            email: defaultEmailAddress,
          };
          return newUser;
        };

        data = {
          user: partnerPersonToUser(),
          partner: this.submissionUserDetails,
          assets: this.assets,
          liabilities: this.liabilities,
          beneficiaries: this.beneficiaries,
          charities: this.charities,
          executors: this.executors,
          gifts: this.gifts,
          guardians: this.guardians,
          contacts: this.contacts,
          contactsRelationships: this.submissionUserDetails.relationships,
          pets: this.pets,
          will: this.will,
          willMeta: this.willMeta,
        };
      }

      return data;
    },
    charityConsentLabel() {
      const notifyCharities = this.willMeta.notify_charities;
      if (notifyCharities === true) {
        return 'YES';
      } else if (notifyCharities === false) {
        return 'NO';
      }
      return 'NOT_PROVIDED';
    },
    userRows() {
      let copyablePhoneNumber =
        this.submissionUserDetails.defaultPhoneNumber?.formatted;
      if (this.submissionUserDetails.defaultPhoneNumber?.prefix) {
        let value = this.submissionUserDetails.defaultPhoneNumber.value;
        if (value.charAt(0) === '0') {
          value = this.submissionUserDetails.defaultPhoneNumber.value.slice(1);
        }
        copyablePhoneNumber = `${this.submissionUserDetails.defaultPhoneNumber.prefix}${value}`;
      }
      return [
        ['ID', this.will?.userId],
        ['Name', this.submissionUserDetails.fullName],
        ['Email', this.submissionUserDetails.defaultEmailAddress],
        ['Phone', copyablePhoneNumber],
      ];
    },
    tableRows() {
      return {
        'About Yourself': (() => {
          let rows = [
            ['First Name', this.submissionUserDetails.firstName],
            ['Middle Name', this.submissionUserDetails.middleName ?? '—'],
            ['Last Name', this.submissionUserDetails.lastName],
            ...(this.willMeta.has_alt_name
              ? [
                  [
                    'Alt First Name',
                    this.submissionUserDetails.altFirstName ?? '—',
                  ],
                  [
                    'Alt Middle Name',
                    this.submissionUserDetails.altMiddleName ?? '—',
                  ],
                  [
                    'Alt Last Name',
                    this.submissionUserDetails.altLastName ?? '—',
                  ],
                ]
              : [['Alt Name', 'No']]),
            [
              'Date Of Birth',
              `
                ${convertIsoDateToLegacyDate(
                  this.submissionUserDetails.dateOfBirth
                )}
                (${this.submissionUserDetails.age} years old)
              `,
            ],
            [
              'Street Address',
              this.submissionUserDetails.residentialAddress.streetAddress,
            ],
            ['Suburb', this.submissionUserDetails.residentialAddress.locality],
            [
              this.submissionUserDetails.residentialAddress.country === 'NZ'
                ? 'Region'
                : 'State',
              this.submissionUserDetails.residentialAddress.region,
            ],
            [
              'Postcode',
              this.submissionUserDetails.residentialAddress.postcode,
            ],
            [
              'Country',
              this.submissionUserDetails.residentialAddress.countryName,
            ],
            ['Has partner?', yesIfTruthy(this.willMeta.has_partner)],
          ];

          if (this.partnerPerson) {
            rows = rows.concat(
              this.getPersonRows(this.partnerPerson, 'Partner')
            );
          }

          return rows;
        })(),
        Guardians: (() => {
          let rows = [
            ['Has children?', yesIfTruthy(this.willMeta.has_children)],
          ];

          this.childrenPersons.forEach((child, i) => {
            const prefix = `Child #${i + 1}`;

            rows = rows.concat(this.getPersonRows(child, prefix));
          });

          rows = rows.concat([
            [
              'Has primary guardian?',
              yesIfTruthy(this.willMeta.has_primary_guardian),
            ],
          ]);

          this.primaryGuardians.forEach((guardian) => {
            rows = rows.concat(
              this.getPersonRows(guardian.directoryPerson, 'Primary')
            );
          });

          rows = rows.concat([
            [
              'Has backup guardian?',
              yesIfTruthy(this.willMeta.has_backup_guardian),
            ],
          ]);

          this.backupGuardians.forEach((guardian) => {
            rows = rows.concat(
              this.getPersonRows(guardian.directoryPerson, 'Backup')
            );
          });

          rows = rows.concat([
            ['Has pets?', yesIfTruthy(this.willMeta.has_pets)],
          ]);

          if (this.willMeta.pet_care_fund) {
            rows = rows.concat([
              ['Pet care fund?', this.willMeta.pet_care_fund],
            ]);
          }

          this.pets.forEach((pet, i) => {
            const meta = metaArrayToObject(pet.meta);
            let prefix = `Pet #${i + 1}`;

            rows = rows.concat([
              [`${prefix} - Name`, meta.name],
              [`${prefix} - Type`, meta.type],
            ]);

            if (pet.guardianDirectoryPerson) {
              prefix = `Pet Guardian #${i + 1}`;

              rows = rows.concat(
                this.getPersonRows(pet.guardianDirectoryPerson, prefix)
              );
            }
          });
          return rows;
        })(),
        Executors: (() => {
          let rows = [];

          rows = rows.concat([['Executors option', this.executorOptionText]]);

          this.primaryExecutors.forEach((executor, i) => {
            const prefix =
              this.primaryExecutors.length > 1
                ? `Primary #${i + 1}`
                : 'Primary Executor';

            rows = rows.concat(
              this.getPersonRows(executor.directoryPerson, prefix)
            );
          });

          this.backupExecutors.forEach((executor, i) => {
            const prefix =
              this.primaryExecutors.length > 1
                ? `Backup #${i + 1}`
                : 'Backup Executor';

            rows = rows.concat(
              this.getPersonRows(executor.directoryPerson, prefix)
            );
          });

          if (!this.executors.length) {
            rows = rows.concat([['No Executors', '–']]);
          }

          return rows;
        })(),
        Estate: (() => {
          let rows = [];
          const isPrimaryEstateSplitEvenly =
            this.willHasLoaded && this.will.isPrimaryEstateSplitEvenly;
          const numberOfPrimaryBeneficiaries = this.beneficiaries.length;

          rows = rows.concat([
            [
              'Is split evenly to all primary beneficiaries?',
              isPrimaryEstateSplitEvenly ? 'Yes' : 'No',
            ],
          ]);

          this.beneficiaries.forEach((beneficiary, i) => {
            const prefix = `Beneficiary #${i + 1}`;

            if (beneficiary.charity) {
              rows = rows.concat(
                this.getCharityRows(beneficiary.charity, prefix)
              );
            } else if (beneficiary.directoryPerson) {
              rows = rows.concat(
                this.getPersonRows(beneficiary.directoryPerson, prefix)
              );
            } else if (beneficiary.cause) {
              rows = rows.concat(this.getCauseRows(beneficiary.cause, prefix));
            }

            rows = rows.concat([
              [
                `${prefix} - Distribution`,
                this.getDistribution(
                  isPrimaryEstateSplitEvenly,
                  numberOfPrimaryBeneficiaries,
                  beneficiary
                ),
              ],
            ]);
            if (beneficiary.directoryPerson) {
              rows = rows.concat([
                [
                  `${prefix} - Is split evenly to all backup?`,
                  beneficiary.isBackupEstateSplitEvenly ? 'Yes' : 'No',
                ],
              ]);
            }

            const backup = beneficiary.meta.estate_backup_split;
            if (backup === 'custom') {
              beneficiary.backup.forEach((backupBeneficiary, j) => {
                const backupPrefix = `Backup #${i + 1}.${j + 1}`;

                if (backupBeneficiary.charity) {
                  rows = rows.concat(
                    this.getCharityRows(backupBeneficiary.charity, backupPrefix)
                  );
                } else if (backupBeneficiary.directoryPerson) {
                  rows = rows.concat(
                    this.getPersonRows(
                      backupBeneficiary.directoryPerson,
                      backupPrefix
                    )
                  );
                } else if (backupBeneficiary.cause) {
                  rows = rows.concat(
                    this.getCauseRows(backupBeneficiary.cause, backupPrefix)
                  );
                }
                rows = rows.concat([
                  [
                    `${backupPrefix} - Distribution`,
                    this.getDistribution(
                      beneficiary.isBackupEstateSplitEvenly,
                      beneficiary.backup.length,
                      backupBeneficiary
                    ),
                  ],
                ]);
              });
            } else if (backup === 'children') {
              rows = rows.concat([[`${prefix} - Backup`, 'Their children']]);
            } else if (backup === 'remaining') {
              rows = rows.concat([
                [`${prefix} - Backup`, 'Remaining beneficiaries'],
              ]);
            }
          });

          if (!this.beneficiaries.length) {
            rows = rows.concat([['No Beneficiaries', '–']]);
          }

          return rows;
        })(),
        Gifts: (() => {
          let rows = [];

          this.gifts.forEach((gift, i) => {
            const meta = metaArrayToObject(gift.meta);
            let prefix = `Gift #${i + 1}`;

            if (meta.type === 'money') {
              rows = rows.concat([
                [`${prefix} - Amount`, formatAUD(meta.amount)],
              ]);
            } else {
              rows = rows.concat([
                [`${prefix} - Description`, meta.description],
              ]);
            }

            rows = rows.concat([[`${prefix} - Note`, dashIfFalsy(meta.note)]]);

            prefix = `Recipient #${i + 1}`;

            if (gift.charity) {
              rows = rows.concat(this.getCharityRows(gift.charity, prefix));
            } else if (gift.recipientDirectoryPerson) {
              rows = rows.concat(
                this.getPersonRows(gift.recipientDirectoryPerson, prefix)
              );
            }
          });

          if (!this.gifts.length) {
            rows = rows.concat([['No Gifts', '–']]);
          }

          return rows;
        })(),
        Assets: (() => {
          let rows = [];

          this.assets.forEach((asset, i) => {
            const meta = metaArrayToObject(asset.meta);
            const prefix = `Asset #${i + 1}`;

            rows = rows.concat([
              [`${prefix} - Type`, meta.type],
              [`${prefix} - Description`, meta.description],
            ]);
          });

          if (!this.assets.length) {
            rows = rows.concat([['No Assets', '–']]);
          }

          this.liabilities.forEach((liability, i) => {
            const meta = metaArrayToObject(liability.meta);
            const prefix = `Liability #${i + 1}`;

            rows = rows.concat([
              [`${prefix} - Type`, meta.type],
              [`${prefix} - Description`, meta.description],
            ]);
          });

          if (!this.liabilities.length) {
            rows = rows.concat([['No Liabilities', '–']]);
          }

          rows = rows.concat([
            ['Liabilities & Assets Note', this.willMeta.asset_note || '–'],
          ]);

          return rows;
        })(),
        Funeral: (() => {
          let rows = [];

          const dict = (val) => {
            let str;

            switch (val) {
              case 'burial':
                str = 'Burial';
                break;
              case 'cremation':
                str = 'Cremation';
                break;
              case 'donate':
                str = 'Donate';
                break;
              case 'executor':
                str = `Let my executor${this.executors.length > 1 ? 's' : ''}`;
                break;
              default:
                str = '–';
                break;
            }

            return str;
          };

          rows = rows.concat([
            ['Funeral Type', dict(this.willMeta.funeral_type)],
            ['Funeral Location', dashIfFalsy(this.willMeta.funeral_location)],
            ['Funeral Note', dashIfFalsy(this.willMeta.funeral_note)],
          ]);

          return rows;
        })(),
      };
    },
    willStatus() {
      return this.willHasLoaded && this.will.status;
    },
    invitedBy() {
      return this.will?.invite?.user?.email;
    },
    invitesSent() {
      return this.invites?.filter((invite) => invite.type !== 'PUBLIC');
    },
    invitesComplete() {
      return this.invites?.filter((invite) => !invite?.redeemable);
    },
    isAdminClassUser() {
      return isAdminOrHigher(this.role);
    },
    userHasPurchasedLegalAdvice() {
      return (
        this.orders &&
        this.orders.some((order) =>
          order.orderItems.some((item) => item.product === 'LEGAL_ADVICE')
        )
      );
    },
    userHasPurchasedLegalAdviceBooking() {
      return (
        this.orders &&
        this.orders.some((order) =>
          order.orderItems.some(
            (item) => item.product === 'LEGAL_ADVICE_BOOKING'
          )
        )
      );
    },
    userHasPurchasedWillTierTwo() {
      return (
        this.orders &&
        this.orders.some((order) =>
          order.orderItems.some((item) => item.product === 'WILL_TIER_TWO')
        )
      );
    },
  },
  watch: {
    willHasLoaded() {
      this.disableButtons();
    },
    async willId(newWillId) {
      if (this.willId !== newWillId) {
        await this.refetchWillData(newWillId);
      }
    },
    hasFlags() {
      this.disableButtons();
    },
    async user(user) {
      if (user) {
        this.getFlags({ planType: 'WILL', userId: this.will.userId });
        this.getPlanFlags({ planId: this.will.id });
        if (user.id) {
          await this.getEnrichedContacts();

          await this.getTools({ ownerId: user.id });
          const willHelpAssessmentTool = this.willHelpAssessmentTool;
          if (willHelpAssessmentTool) {
            await this.getWillHelpAssessmentVaultItems({
              ids: willHelpAssessmentTool.vaultItemIds,
              ownerId: user.id,
            });
          }
        }
      }
    },
  },
  async mounted() {
    await this.refetchWillData(this.willId);
    this.setSubmissionsWillIds(this.submissions.map((will) => will.id));
  },
  methods: {
    ...mapActions(['setWillStatus']),
    ...mapActions('orders', ['getOrders']),
    ...mapActions('directory-person', [
      'getDirectoryPersonsByOwnerId',
      'getDirectoryPersonsByOwnerIdData',
    ]),
    ...mapActions('flag', ['getFlags', 'getPlanFlags']),
    ...mapActions('admin/submissions', [
      'nextWillId',
      'previousWillId',
      'setSubmissionsWillIds',
    ]),
    ...mapActions('admin/masquerade', ['enterMasquerade']),
    ...mapActions('tool', ['getTools']),
    ...mapActions('user/pets', ['getPets']),
    ...mapActions('vault-item', ['getWillHelpAssessmentVaultItems']),
    formatDate,
    async refetchWillData(willId) {
      await Promise.all([
        this.getPets(willId),
        this.getGiftsData(willId),
        this.getExecutorsData(willId),
      ]);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async nextWill() {
      const nextWillId = await this.nextWillId(this.will.id);
      if (nextWillId) {
        await this.$router.push({
          path: this.localePath(`/admin/submissions/${nextWillId}`),
        });
      } else {
        await this.$router.push({
          path: this.localePath(`/admin/submissions`),
        });
      }
    },
    async previousWill() {
      const previousWillId = await this.previousWillId(this.will.id);
      if (previousWillId) {
        await this.$router.push({
          path: this.localePath(`/admin/submissions/${previousWillId}`),
        });
      } else {
        await this.$router.push({
          path: this.localePath(`/admin/submissions`),
        });
      }
    },
    disableButtons() {
      this.approveWillIsDisabled =
        this.will.archived ||
        this.willStatus !== 'AWAITING_APPROVAL' ||
        this.hasFlags ||
        !this.will.willFileId;
      this.downloadPdfIsDisabled = !this.will.willFileId;
      this.flagsAreDisabled =
        this.will.archived || this.willStatus !== 'AWAITING_APPROVAL';
      this.transferWillIsDisabled =
        this.will.archived || this.willStatus !== 'APPROVED';
      this.flagWillIsDisabled =
        this.will.archived ||
        !this.hasFlags ||
        this.willStatus !== 'AWAITING_APPROVAL';
      this.versionWillIsDisabled =
        this.will.archived ||
        !['APPROVED', 'FLAGGED', 'AWAITING_APPROVAL'].includes(this.willStatus);
    },
    async approveWill() {
      this.approvingWill = true;
      await this.updateWillStatus('APPROVED');
      this.approvingWill = false;
    },
    async validateWill() {
      this.validatingWill = true;
      let validWill = false;
      await this.$apollo
        .mutate({
          mutation: VALIDATE_WILL_QUERY,
          variables: {
            id: this.willId,
          },
        })
        .then(({ data }) => {
          const validateResponse = data.validateWill;
          this.$nuxt.$emit('snackbar', {
            placement: 'top-right',
            type: validateResponse.isValid ? 'success' : 'error',
            text: validateResponse.isValid
              ? validateResponse.message
              : validateResponse.errors
                ? this.formatDirectoryPersonValidationErrors(
                    validateResponse.errors
                  )
                : validateResponse.message,
          });
          validWill = validateResponse.isValid;
        })
        .catch((e) => {
          console.error(e);
          this.$nuxt.$emit('snackbar', {
            placement: 'top-right',
            type: 'error',
            text: formatError(e.message),
          });
        });

      this.validatingWill = false;
      return validWill;
    },
    formatDirectoryPersonValidationErrors(errors) {
      return errors.map((error) => {
        const name = this.contacts.find(
          (contact) => contact.id === error.entityId
        ).fullName;
        return `${name}: ${this.$t(error.message)}`;
      });
    },
    getDistribution(isSplitEvenly, numberOfBeneficiaries, currentBeneficiary) {
      if (isSplitEvenly) {
        let distribution = 100 / numberOfBeneficiaries;
        distribution = Math.floor(distribution * 100) / 100;
        return `1/${numberOfBeneficiaries} (~${distribution}%)`;
      }

      return `${currentBeneficiary.distribution}%`;
    },
    getCharityRows(charity, prefix) {
      const meta = Array.isArray(charity.meta)
        ? metaArrayToObject(charity.meta)
        : charity.meta;

      return [
        [`${prefix} - Name`, meta.name],
        [`${prefix} - Address`, meta.address],
      ];
    },
    getRelationshipToUser(directoryPerson) {
      if (!this.submissionUserDetails) {
        return 'UNKNOWN';
      }
      const relationship = this.submissionUserDetails.relationships.find(
        (relationship) => relationship.toPersonId === directoryPerson.id
      );

      return relationship ? relationship.type : 'UNKNOWN';
    },
    getPersonRows(directoryPerson, prefix) {
      directoryPerson = this.contacts.find(
        (contact) => contact.id === directoryPerson.id
      );

      const rows = [
        [`${prefix} - Name`, directoryPerson.fullName],
        ...(directoryPerson.residentialAddress.fullAddress
          ? [
              [
                `${prefix} - Address`,
                directoryPerson.residentialAddress.fullAddress,
              ],
            ]
          : []),
        ...(directoryPerson.defaultEmailAddress
          ? [[`${prefix} - Email`, directoryPerson.defaultEmailAddress]]
          : []),
        ...(directoryPerson.defaultPhoneNumber?.formatted
          ? [
              [
                `${prefix} - Phone number`,
                directoryPerson.defaultPhoneNumber.formatted,
              ],
            ]
          : []),
        ...(convertIsoDateToLegacyDate(directoryPerson.dateOfBirth)
          ? [
              [
                `${prefix} - DOB`,
                convertIsoDateToLegacyDate(directoryPerson.dateOfBirth),
              ],
            ]
          : []),
        [`${prefix} - Over 18?`, yesIfTruthy(directoryPerson.over18)],
      ];

      if (prefix !== 'Partner' && !prefix.startsWith('Child')) {
        const relationshipToUser = startCase(
          toLower(this.getRelationshipToUser(directoryPerson))
        );
        rows.push([`${prefix} - Relationship to user`, relationshipToUser]);
      }

      if (prefix.startsWith('Child') && this.partnerPerson?.id) {
        const otherPartnerIsParent = directoryPerson.relationships.some(
          (relationship) =>
            relationship.fromPersonId === this.partnerPerson.id &&
            relationship.type === 'CHILD'
        );
        rows.push([
          `${prefix} - Partner other parent?`,
          yesIfTruthy(otherPartnerIsParent),
        ]);
      }

      return rows;
    },
    getCauseRows(cause, prefix) {
      return [
        [`${prefix} - Cause`, cause.displayName],
        [
          `${prefix} - Charities`,
          cause.charities
            .map((charity) => {
              const meta = Array.isArray(charity.meta)
                ? metaArrayToObject(charity.meta)
                : charity.meta;
              return meta.name;
            })
            .join(', '),
        ],
      ];
    },
    flagWill() {
      this.$confirm(
        {
          title: this.$t(
            'pages.admin.submissions.submission.actions.flag.confirm.title'
          ),
          message: this.$t(
            'pages.admin.submissions.submission.actions.flag.confirm.body'
          ),
        },
        async (action) => {
          if (action) {
            this.flaggingWill = true;
            await this.updateWillStatus('FLAGGED');
            this.flaggingWill = false;
            this.disableButtons();
          }
        }
      );
    },
    async updateWillStatus(status) {
      await this.$apollo
        .mutate({
          mutation: UPDATE_WILL_STATUS_MUTATION,
          variables: {
            id: this.willId,
            status,
          },
        })
        .then(async () => {
          await this.$apollo.queries.will.refetch();
        })
        .catch((e) => {
          console.error(e);
          this.$nuxt.$emit('snackbar', {
            placement: 'top-right',
            type: 'error',
            text: formatError(e.message),
          });
        });
    },
    transferAffiliateWill(newTransferAffiliateId) {
      this.will.transferAffiliateId = newTransferAffiliateId;
      this.downloadJSON();
    },
    versionWill() {
      this.$confirm(
        {
          title: this.$t(
            'pages.admin.submissions.submission.actions.version.confirm.title'
          ),
          message: this.$t(
            'pages.admin.submissions.submission.actions.version.confirm.body'
          ),
        },
        async (action) => {
          if (action) {
            this.versioningWill = true;
            try {
              await this.$apollo.mutate({
                mutation: VERSION_WILL,
                variables: {
                  id: this.will.id,
                },
              });

              await this.$apollo.queries.getWillByEmail.refetch();
              await this.$apollo.queries.will.refetch();

              this.$nuxt.$emit('snackbar', {
                placement: 'top-right',
                type: 'success',
                text: this.$t(
                  'pages.admin.submissions.submission.actions.version.successToast'
                ),
              });
            } catch (e) {
              console.error(e);
              this.$nuxt.$emit('snackbar', {
                placement: 'top-right',
                type: 'error',
                text: formatError(e.message),
              });
            }
            this.versioningWill = false;
          }
        }
      );
    },
    async generateWill() {
      const validWill = await this.validateWill();
      if (!validWill) {
        return;
      }

      this.generatingWill = true;
      this.$nuxt.$emit('snackbar', {
        placement: 'top-right',
        text: 'Generating will',
      });
      try {
        const { data } = await this.$apollo.mutate({
          mutation: GENERATE_WILL_MUTATION,
          variables: {
            id: this.will.id,
          },
        });
        if (data.generateWill?.will.willFileId) {
          Object.keys(this.will).forEach((key) => {
            this.will[key] = data.generateWill.will[key];
          });

          this.setWillStatus(data.generateWill.will.status);
          console.info('Generated:', data);
        } else {
          throw new Error('Will generation failed');
        }
      } catch (e) {
        console.error(e.message);
        this.will.willFileId = null;
        this.will.wordFileId = null;
      }
      this.generatingWill = false;
      this.disableButtons();
    },
    async downloadWillDoc() {
      const data = await fetch(this.doc);
      const blob = await data.blob();
      Object.assign(document.createElement('a'), {
        href: window.URL.createObjectURL(blob),
        download:
          `safewill-${this.will.hashId}_${this.submissionUserDetails.lastName},${this.submissionUserDetails.firstName}.doc`.toLowerCase(),
      }).click();
    },
    async downloadWillPdf() {
      await this.$downloadFile(this.will.willFileId, this.user.id);
    },
    async downloadJSON() {
      await Promise.allSettled([
        this.refetchWill(),
        this.$apollo.queries.beneficiaries.refetch(),
        this.getExecutorsData(this.willId),
        this.$apollo.queries.guardians.refetch(),
        this.getPets(this.willId),
        this.getGiftsData(this.willId),
        this.$apollo.queries.assets.refetch(),
        this.$apollo.queries.liabilities.refetch(),
      ]);

      const willBase = cloneDeep(this.will);

      willBase.beneficiaries = this.beneficiaries;
      willBase.executors = this.executors;
      willBase.gifts = this.gifts;
      willBase.pets = this.pets;
      willBase.guardians = this.guardians;
      willBase.assets = this.assets;
      willBase.liabilities = this.liabilities;

      recursiveRemoveKey(willBase, '__typename');
      recursiveRemoveKey(willBase, 'id');
      delete willBase.status;
      delete willBase.transferAffiliateId;
      delete willBase.transferredAt;
      delete willBase.userId;
      delete willBase.sourceWillId;

      const json = JSON.stringify(willBase, null, 2);
      const blob = new Blob([json], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      Object.assign(document.createElement('a'), {
        href: url,
        download:
          `safewill-${this.will.hashId}-${this.submissionUserDetails.lastName}_${this.submissionUserDetails.firstName}.json`.toLowerCase(),
      }).click();
    },
    async getEnrichedContacts() {
      this.loading = true;
      try {
        const contacts = await this.getDirectoryPersonsByOwnerIdData({
          ownerId: this.user?.id,
          excludeSelf: false,
        });
        this.contacts = contacts;

        if (contacts) {
          const { userDetails, partnerPerson, childrenPersons } =
            separateContactsByRole(this.user.person_id, contacts);
          this.submissionUserDetails = userDetails;
          this.partnerPerson = partnerPerson;
          this.childrenPersons = childrenPersons;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
