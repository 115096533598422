
import { mapActions, mapGetters } from 'vuex';
import { formatPrice } from '@/utilities';
export default {
  name: 'AppWillWillTierTwoUpgradeModal',
  props: {
    overrideSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('application/prices', ['willPrice', 'willTierTwoPrice']),
    ...mapGetters('will-tiers', ['hasPurchasedWillProduct', 'isLoading']),
    formatedWillTierTwoPrice() {
      return this.formatPrice(this.willTierTwoPrice);
    },
    formatedAdditionalPrice() {
      return this.formatPrice(this.willTierTwoPrice - this.willPrice);
    },
    modalTitle() {
      if (
        this.hasPurchasedWillProduct ||
        this.formatedAdditionalPrice === this.formatedWillTierTwoPrice
      ) {
        return this.$t(
          'components.willTierTwo.upgradeModal.ups.willTierTwoTitle',
          {
            additionalPrice: this.formatedWillTierTwoPrice,
          }
        );
      }
      return this.$t('components.willTierTwo.upgradeModal.ups.title', {
        additionalPrice: this.formatedAdditionalPrice,
        willTierTwoPrice: this.formatedWillTierTwoPrice,
      });
    },
    faqs() {
      return this.$t('components.willTierTwo.upgradeModal.faqs', {
        willTierTwoPrice: this.formatPrice(this.willTierTwoPrice),
        willPrice: this.formatPrice(this.willPrice),
      })
        .filter((faq) => {
          return !faq.featureFlag || this.$ff[faq.featureFlag]() !== false;
        })
        .map((faq, faqIndex) => {
          faq.descriptions = faq.descriptions.map((description, descIndex) => {
            if (description.type === 'text') {
              description.body = this.$t(
                `components.willTierTwo.upgradeModal.faqs[${faqIndex}].descriptions[${descIndex}].body`,
                {
                  willTierTwoPrice: this.formatPrice(this.willTierTwoPrice),
                  willPrice: this.formatPrice(this.willPrice),
                }
              );
            }
            return description;
          });
          return faq;
        });
    },
  },
  methods: {
    formatPrice,
    ...mapActions('user/cart', ['addToCart']),
    ...mapActions('will-tiers', ['addWillTierTwoToCart']),
    async upgrade() {
      if (!this.overrideSubmit) {
        await this.addWillTierTwoToCart();
      }
      this.$emit('upgraded');
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
