
import { formatDate } from '@/utilities';

export default {
  name: 'AppUserHistoricalWills',
  props: {
    wills: {
      type: Array,
      require: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      columns: ['ID', 'Date', 'Status'],
    };
  },
  methods: {
    formatDate,
  },
};
