
import { debounce } from 'throttle-debounce';
import { mapActions, mapGetters } from 'vuex';

import confirm from '@/mixins/confirm';
import sorting from '@/mixins/sorting';
import { formatPrice, formatDate } from '@/utilities';

export default {
  name: 'PagesAffiliateAdminCouponsIndex',
  mixins: [confirm, sorting],
  layout: 'admin',
  middleware: ['adminAuthenticated'],
  data() {
    return {
      columns: [
        'Code',
        'Partnership',
        'Discount',
        'Description',
        'Created At',
        'Active',
      ],
      statusFilters: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Active',
          value: true,
        },
        {
          label: 'Inactive',
          value: false,
        },
      ],
      sortableFields: {
        name: {
          field: 'code',
          columnName: 'Code',
          defaultDirection: 'ASC',
        },
        createdAt: {
          field: 'createdAt',
          columnName: 'Created At',
          defaultDirection: 'DESC',
        },
        discount: {
          field: 'factor',
          columnName: 'Discount',
          defaultDirection: 'ASC',
        },
        description: {
          field: 'description',
          columnName: 'Description',
          defaultDirection: 'ASC',
        },
      },
      currentSortingColumn: ['Created At', 'DESC'],
      couponModalIsVisible: false,
      bulkCreateCouponModalIsVisible: false,
      bulkDownloadCouponModalIsVisible: false,
      searchInput: null,
    };
  },
  computed: {
    ...mapGetters('admin/coupons', [
      'couponsPageSize',
      'couponsPageIndex',
      'couponsFilters',
      'coupons',
      'couponsPartnershipOptions',
    ]),
    ...mapGetters('product', ['products']),
    partnershipFilter: {
      get() {
        return this.couponsFilters.partnershipId;
      },
      set(value) {
        this.setFilters({
          partnershipId: value || null,
        });
      },
    },
  },
  async mounted() {
    await this.refetchCouponsPartnershipOptions();
    await this.refetchCoupons();
  },
  methods: {
    ...mapActions('admin/coupons', [
      'nextCouponsPage',
      'previousCouponsPage',
      'resetCouponsPageIndex',
      'setCouponsSearchQuery',
      'setCouponsFilters',
      'refetchCoupons',
      'refetchCouponsPartnershipOptions',
      'toggleCouponActiveStatus',
    ]),
    formatDate,
    async setFilters(value) {
      this.setCouponsFilters({
        ...this.couponsFilters,
        ...value,
      });
      this.resetCouponsPageIndex();
      await this.refetchCoupons();
    },
    getDiscountProducts(coupon) {
      return coupon.discountProducts
        .map((product) => {
          const productCode = this.products.find(
            (p) => p.id === product.productId
          );
          if (!productCode) return null;
          return {
            label: this.$t(`commons.products.${productCode.code}`),
            value:
              product.discountType === 'PERCENTAGE'
                ? `${product.discountFactor}%`
                : formatPrice(product.discountFactor),
          };
        })
        .filter(Boolean);
    },
    showCouponModal() {
      this.couponModalIsVisible = true;
    },
    toggleBulkCreateCouponModal() {
      this.bulkCreateCouponModalIsVisible =
        !this.bulkCreateCouponModalIsVisible;
    },
    toggleBulkDownloadCouponModal() {
      this.bulkDownloadCouponModalIsVisible =
        !this.bulkDownloadCouponModalIsVisible;
    },
    hideCouponModal() {
      this.couponModalIsVisible = false;
    },
    search: debounce(300, function (event) {
      this.resetCouponsPageIndex();
      this.setCouponsSearchQuery(event.target.value.trim());
      this.refetchCoupons();
    }),
    async addedBulkCoupons() {
      this.$nuxt.$emit('snackbar', {
        placement: 'top-right',
        type: 'success',
        text: 'Coupons added successfully.',
      });
      await this.addedCoupon();
    },
    failedRequest(context) {
      let errorText = '';
      switch (context) {
        case 'bulkCreate':
          errorText =
            'An error occurred creating the coupons. Please contact development.';
          break;
        case 'bulkDownloads':
          errorText =
            'An error occurred downloading the coupons. Please contact development.';
          break;
        default:
          errorText = 'An error occurred. Please contact development.';
      }
      this.$nuxt.$emit('snackbar', {
        placement: 'top-right',
        type: 'error',
        text: errorText,
      });
    },
    async addedCoupon() {
      await this.refetchCoupons();
    },
    setCouponStatus(coupon) {
      const title = coupon.active
        ? `Deactivate ${coupon.code.toUpperCase()}`
        : `Activate ${coupon.code.toUpperCase()}`;
      const message = coupon.active
        ? `Are you sure you want to deactivate this coupon?`
        : `Are you sure you want to activate this coupon?`;

      this.$confirm({ title, message }, (action) => {
        if (action) {
          this.toggleCouponActiveStatus(coupon);
        }
      });
    },
    async changeSortColumnAndResetPageIndex(columnName) {
      this.changeSortColumn(columnName);
      this.resetCouponsPageIndex();
      await this.refetchCoupons();
    },
  },
};
