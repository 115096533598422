
import { mapGetters, mapActions } from 'vuex';
import { beneficiaries, user, will } from '@/mixins/apollo';
import { RELATIONSHIP_TYPE } from '@/utilities/constants';

export default {
  name: 'PagesAffiliateWillAboutYourselfPartner',
  mixins: [beneficiaries, user, will],
  data() {
    return {
      RELATIONSHIP_TYPE,
      selectedContacts: [],
      partnerWillAddOnIsVisible: false,
    };
  },
  computed: {
    ...mapGetters('application/prices', ['partnerWillPrice']),
    ...mapGetters('directory-person', [
      'userDetails',
      'userPartner',
      'userHasPartner',
      'contacts',
    ]),
    isComplete() {
      return !!(
        (this.willMeta && this.willMeta.has_partner === false) ||
        this.selectedContacts.length
      );
    },
    partnerWillIsFree() {
      return this.partnerWillPrice === 0;
    },
  },
  watch: {
    'willMeta.has_partner'(value) {
      if (value) {
        this.initSelectedContact();
      }
    },
  },
  mounted() {
    this.unWatchSelectedPartners = this.$watch(
      () => {
        return this.selectedContacts;
      },
      this.setPartnerWillVisibility,
      { immediate: true }
    );
    if (this.userHasPartner) {
      this.initSelectedContact();
    }
  },
  methods: {
    ...mapActions('user/cart', ['addToCart', 'getCartItems']),
    setPartnerWillVisibility() {
      if (this.selectedContacts.length) {
        if (!this.partnerWillIsFree) {
          this.partnerWillAddOnIsVisible = true;
        }
        if (this.unWatchSelectedPartners) {
          this.unWatchSelectedPartners();
        }
      }
    },
    initSelectedContact() {
      this.selectedContacts = this.userHasPartner ? [this.userPartner.id] : [];
    },
    async done() {
      let partnerHasChanged = false;

      if (this.willMeta.has_partner) {
        if (
          this.userHasPartner &&
          (!this.selectedContacts.length ||
            !this.selectedContacts.includes(this.userPartner?.id))
        ) {
          partnerHasChanged = true;
          this.$nuxt.$emit('sendTrackingAttributes', {
            partner_name: null,
          });
        }
        if (
          this.selectedContacts.length &&
          !this.selectedContacts.includes(this.userPartner?.id)
        ) {
          partnerHasChanged = true;
          const newPartner = this.contacts.find(
            (contact) => contact.id === this.selectedContacts[0]
          );
          this.$nuxt.$emit('sendTrackingAttributes', {
            partner_name: newPartner.fullName,
          });
        }
      } else if (this.userPartner) {
        partnerHasChanged = true;
        this.$nuxt.$emit('sendTrackingAttributes', {
          partner_name: null,
        });
      }

      if (
        this.willMeta.estate_split === 'partner' &&
        (this.willMeta.has_partner === false || partnerHasChanged)
      ) {
        this.willMeta.estate_split = null;
        await this.removeAllBeneficiaries();
        await this.updateWillMeta();
      }

      if (this.willMeta.has_partner && this.partnerWillIsFree) {
        try {
          await this.addToCart({
            codes: ['WILL', 'PARTNER_WILL'],
            showSnackbar: false,
          });
        } catch {
          this.$sentry.captureMessage(
            'Failed to add free partner will to cart'
          );
        }
      }

      this.$router.push({
        path: this.localePath('/will/about-yourself/phone'),
      });
    },
  },
};
